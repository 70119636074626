import React from 'react'
import { useTranslation } from 'react-i18next';


export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className='Footer'>
      <div className='logo'><img src={'./img/logov23.png'} alt="logo" /></div>
      <div className='adress'>
        <div className='contact'>{t('contact')}:</div>
        <div className='tel'>email: office@green-bean.eu</div>
        <div className='tel'>Mobile/Whatsapp: +48 885 546 884</div>
        <div>Jedlec 61, 63-322 Gołuchów, Poland</div>
        <div className='tel'>{t('tax')}: PL6080068114</div>
      </div>
    </div>
  )
}
