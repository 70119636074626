import React from 'react'
import { useTranslation } from 'react-i18next';


export default function Logistic() {
    const { t } = useTranslation();

    return (
        <div className='Logistic'>
            <div className='head'>{t('logistic')}</div>
            <div className='grid'>
                <div className='image'>
                    <img src={"./img/logistic.jpg"} alt="logistic image" />
                </div>
                <div className='text'>
                {t('logistic_text')}
                </div>
            </div>
            {/* <div className='text'>
                Welcome to our Logistics &amp; Packaging Solutions! We understand that efficient
                logistics and reliable packaging are crucial elements of a successful business.
            </div> */}
        </div>
    )
}
