import React from 'react'
import { useTranslation } from 'react-i18next';


export default function Raw() {
    const { t } = useTranslation();

    return (
        <div className='Raws'>
            <div className='raw'>
                <div className='item'>
                    <img src={"./img/apple.jpg"} alt="image" />
                </div>
                <div className='item text_box'>
                    <div className='title'>{t('products_row.fruits_head')}</div>
                    <div className='text'>
                        {t('products_row.fruits_text')}
                    </div>
                </div>
                <div className='item text_box'>
                    <div className='title'>{t('products_row.vegetables_head')}</div>
                    <div className='text'>
                        {t('products_row.vegetables_text')}
                    </div>
                </div>
                <div className='item'>
                    <img src={"./img/vegetable.jpg"} alt="image" />
                </div>
                <div className='item'>
                    <img src={"./img/organic.jpg"} alt="image" />
                </div>
                <div className='item text_box'>
                    <div className='title'>{t('products_row.conventional_head')}</div>
                    <div className='text'>
                        {t('products_row.conventional_text')}
                    </div>
                </div>
                <div className='item text_box'>
                    <div className='title'>{t('products_row.mushrooms_head')}</div>
                    <div className='text'>
                        {t('products_row.mushrooms_text')}
                    </div>
                </div>
                <div className='item'>
                    <img src={"./img/mushrooms.jpg"} alt="image" />
                </div>
            </div>
        </div>
    )
}
