import React, { useRef, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';


export default function Product() {
    const { t } = useTranslation();
    const myElementRef = useRef(null);



    useEffect(() => {
        const myElement = myElementRef.current;
        myElement.scrollIntoView();
    }, []);

    const images = [
        { src: '/img/products/1x1/9.jpeg', name: 'products_page.apples' },
        { src: '/img/products/1x1/17.png', name: 'products_page.broccoli' },
        { src: '/img/products/1x1/22.png', name: 'products_page.garlic' },
        { src: '/img/products/1x1/27.png', name: 'products_page.cauliflower' },
        { src: '/img/products/1x1/37.png', name: 'products_page.tomatoes' },
        { src: '/img/products/1x1/1.jpeg', name: 'products_page.potatoes' },
        { src: '/img/products/1x1/3.jpeg', name: 'products_page.carrot' },
        { src: '/img/products/1x1/2.jpeg', name: 'products_page.onion' },
        { src: '/img/products/1x1/4.jpeg', name: 'products_page.white_cabbage' },
        { src: '/img/products/1x1/6.jpeg', name: 'products_page.red_cabbage' },
        { src: '/img/products/1x1/5.jpeg', name: 'products_page.beetroots' },
        { src: '/img/products/1x1/7.jpeg', name: 'products_page.frozen_fruit' },
        { src: '/img/products/1x1/8.jpeg', name: 'products_page.frozen_vegetables' },
        { src: '/img/products/1x1/10.jpeg', name: 'products_page.champignons' },
        { src: '/img/products/1x1/11.jpeg', name: 'products_page.oyster_mushroom' },
        { src: '/img/products/1x1/8.png', name: 'products_page.raspberries' },
        { src: '/img/products/1x1/11.png', name: 'products_page.currants' },
        { src: '/img/products/1x1/13.png', name: 'products_page.strawberries' },
        { src: '/img/products/1x1/15.png', name: 'products_page.cherries' },
        { src: '/img/products/1x1/40.png', name: 'products_page.radish' },
        { src: '/img/products/1x1/41.png', name: 'products_page.lettuce' },
        { src: '/img/products/1x1/42.jpg', name: 'products_page.parsley' },
        { src: '/img/products/1x1/1.png', name: 'products_page.blueberries' },
        { src: '/img/products/1x1/34.png', name: 'products_page.pepper' },


        // Add all your images here
    ];


    return (
        <div className='Product'>
            <div ref={myElementRef}></div>
            <div className='baner'>
                <div className='text'>
                    {t('products_page.head.vegetables')}
                    {/* Test */}
                </div>
                <div className='image'>
                    <img src='img/slider1.jpg' />
                </div>
            </div>

            <div className='catalog'>
               {/* <div className="grid"> */}
                   {images.map((image, index) => (
                       // <div key={index} className="item">
                       //     <div className='img-container'>
                       //         <img src={image.src} alt={image.name} />
                       //     </div>
                       //     <p className='name'>{t(image.name.toLowerCase())}</p>
                       // </div>
                       <div key={index} class="product">
                           <img src={image.src} alt={image.name}  />
                           <h2>{t(image.name)}</h2>

                           {/* <p class="price">$30 per 50 lbs bag</p> */}
                           {/* <p>Our fresh organic potatoes are sourced from the finest farms.</p> */}
                       </div>
                   ))}
               {/* </div> */}
           </div>
        </div>
    )
}
