// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// Import your local translation files
// import translationEN from './locales/en/translation.json';
import translationEN from './locales/en/translation.json'
import translationDE from './locales/de/translation.json'
import translationPL from './locales/pl/translation.json'
import translationIT from './locales/it/translation.json'
import translationFR from './locales/fr/translation.json'





// Resources object containing your translations
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE, // Example for Spanish
  },
  pl: {
    translation: translationPL, // Example for Spanish
  },
  it: {
    translation: translationIT,
  },
  fr: {
    translation: translationFR,
  }
};

i18n
  // Removed the .use(Backend) since we're not using a backend loader
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources, // Add the resources to i18next configuration
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;