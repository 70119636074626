import React from 'react'
import { useTranslation } from 'react-i18next';


export default function Certificate() {
    const { t } = useTranslation();

    return (
        <div className='Certificate'>
            <div className='head'>{t('certificate.head')}</div>
            <div className='grid'>
                <div className='text'>
                    <div className='text1'>
                        {t('certificate.text1')}
                    </div>
                    <div>
                    {t('certificate.text2')}    
                    </div>
                    <img src={"./img/docs/LOGO - EKO Listek UE.jpg"} alt="certificate" />
                </div>
                <div className='image' >
                    <a href="/PL-EKO-06.616-0024844.2024.002 - EN.pdf" target="_blank" rel="noopener noreferrer">
                        <img src={"./img/docs/certificatet.jpg"} alt="certificate" />
                    </a>
                </div>
            </div>
            {/* <div className='text'>
                Welcome to our Logistics &amp; Packaging Solutions! We understand that efficient
                logistics and reliable packaging are crucial elements of a successful business.
            </div> */}
        </div>
    )
}
