import React, { useState, useRef, useEffect } from 'react';

const Test = () => {




  return (
    <div
      style={{
        margin: '500px',
        width: '500px',
        height: '500px',

        fontFamily: '"IM Fell English SC", serif',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '40px',
        color: 'rgb(0, 128, 48)' 
      }}
    >
      BreenBean
    </div>
  );
};

export default Test;